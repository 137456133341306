var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"mnotify-card",attrs:{"id":"account-settings"}},[_c('div',{staticClass:"mnotify-card-header",attrs:{"id":"side-indicators-content"}},[_c('div',{staticClass:"profile-settings-section-header"},[_c('profilesettingsBtn'),_c('div',{staticClass:"profile-settings-sub-header"},[_c('h3',{staticClass:"mnotify-card-header-text pb-2 my-0"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.account_settings.change_password.title"))+" ")]),_c('p',{staticClass:"profile-description"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.account_settings.change_password.sub_text"))+" ")])])],1)]),(_vm.showOTP === false)?_c('div',{staticClass:"mnotify-card-body"},[_c('h6',{staticClass:"warning"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.account_settings.change_password.note"))+" ")]),_c('div',{staticClass:"row profile-settings-form",staticStyle:{"margin-top":"2em"}},[_c('div',{staticClass:"profile_form"},[_c('div',{staticClass:"profile-inner"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.formData,"rules":_vm.rules},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.checkEnter.apply(null, arguments)}}},[_c('div',{staticClass:"form_input_container"},[_c('el-form-item',{attrs:{"label":_vm.$t(
                      'dashboard.account_settings.change_password.form.current_password.label'
                    ),"prop":"old_password"}},[_c('el-input',{attrs:{"type":"password","show-password":"","autocomplete":"off","placeholder":_vm.$t(
                        'dashboard.account_settings.change_password.form.current_password.placeholder'
                      )},model:{value:(_vm.formData.old_password),callback:function ($$v) {_vm.$set(_vm.formData, "old_password", $$v)},expression:"formData.old_password"}})],1)],1),_c('div',{staticClass:"form_input_container"},[_c('el-form-item',{attrs:{"label":_vm.$t(
                      'dashboard.account_settings.change_password.form.new_password.label'
                    ),"prop":"password"}},[_c('el-input',{attrs:{"type":"password","show-password":"","autocomplete":"off","placeholder":_vm.$t(
                        'dashboard.account_settings.change_password.form.new_password.placeholder'
                      )},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})],1)],1),_c('div',{staticClass:"form_input_container"},[_c('el-form-item',{attrs:{"label":_vm.$t(
                      'dashboard.account_settings.change_password.form.confirm_password.label'
                    ),"prop":"password_confirmation"}},[_c('el-input',{attrs:{"type":"password","show-password":"","autocomplete":"off","placeholder":_vm.$t(
                        'dashboard.account_settings.change_password.form.confirm_password.placeholder'
                      )},model:{value:(_vm.formData.password_confirmation),callback:function ($$v) {_vm.$set(_vm.formData, "password_confirmation", $$v)},expression:"formData.password_confirmation"}})],1)],1),(_vm.errors)?_c('Alert',{attrs:{"message":_vm.errors,"type":_vm.type}}):_vm._e(),_c('div',{staticClass:"terms_and_conditons_container mt-5"},[_c('el-form-item',[_c('el-button',{attrs:{"id":"mNotify_form_submit_btn","loading":_vm.loading,"type":"primary"},on:{"click":function($event){return _vm.requestOTP('ruleForm')}}},[_vm._v("Change Password")])],1)],1)],1)],1)])])]):_c('div',{staticClass:"mnotify-card-body"},[_c('h6',{staticClass:"warning"},[_vm._v(" You will be logged out after resetting your password ")]),_c('div',{staticClass:"row profile-settings-form",staticStyle:{"margin-top":"2em"}},[_c('div',{staticClass:"profile_form"},[_c('div',{staticClass:"profile-inner"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.formData,"rules":_vm.rules},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.checkEnter.apply(null, arguments)}}},[_c('div',{staticClass:"form_input_container"},[_c('el-form-item',{attrs:{"label":"Enter OTP to confirm password change"}},[_c('el-input',{attrs:{"type":"number","show-password":"","autocomplete":"off","placeholder":"Enter OTP"},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}})],1)],1),(_vm.errors)?_c('Alert',{attrs:{"message":_vm.errors,"type":_vm.type}}):_vm._e(),_c('div',{staticClass:"terms_and_conditons_container mt-5"},[_c('el-form-item',[_c('el-button',{attrs:{"id":"mNotify_form_submit_btn","loading":_vm.loading,"type":"primary"},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v(_vm._s(_vm.$t( "dashboard.account_settings.change_password.form.button" )))])],1)],1)],1)],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }