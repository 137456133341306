<script>
  import appConfig from "@/app.config";
  import store from "@/state/store";
  export default {
    page: {
      title: "change password",
      meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
      var validatePass2 = (rule, value, callback) => {
        if (this.formData.password_confirmation !== this.formData.password) {
          callback(new Error("Password do not match"));
        } else {
          callback();
        }
      };
      return {
        phoneNumber: "",
        errors: "",
        loading: false,
        type: "alert-danger",
        showOTP: false,
        formData: {
          old_password: "",
          password: "",
          password_confirmation: "",
          code: "",
        },
        rules: {
          old_password: [
            {
              required: true,
              message: "Current password is required.",
              trigger: "change",
            },
            {
              min: 6,
              message: "Password must be at least 6 characters.",
              trigger: "blur",
            },
          ],
          password: [
            {
              required: true,
              message: "Password is required.",
              trigger: ["blur", "change"],
            },
            {
              min: 6,
              message: "Password must be at least 6 characters.",
              trigger: "blur",
            },
          ],
          password_confirmation: [
            { validator: validatePass2, trigger: "blur" },
            {
              required: true,
              message: "Please re-type your password",
              trigger: "change",
            },
          ],
        },
      };
    },
    methods: {
      submitForm(formName) {
        this.errors = "";
        this.loading = true;
        this.formData.code = this.formData.code.toString();
        this.$refs[formName].validate((valid) => {
          if (valid) {
            store
              .dispatch("auth/updatePassword", this.formData)
              .then((response) => {
                this.loading = false;
                this.$notify({
                  title: "Success",
                  message: response.data.message,
                  type: "success",
                });
                this.$router.push("/profile-settings").catch((err) => {
                  if (err.name !== "NavigationDuplicated") throw err;
                });
              })
              .catch((err) => {
                this.loading = false;
                if (err.response.data == undefined) {
                  this.errors = "Network error try again";
                }
                if (err.response && err.response.status === 404) {
                  this.errors = err.response.data.data.error;
                }
              });
          } else {
            this.loading = false;
            return false;
          }
        });
      },
      requestOTP(formName) {
        this.errors = "";
        this.loading = true;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            store
              .dispatch("auth/sendGeneralOTP", {
                message: "OTP for password reset is ",
                old_password: this.formData.old_password,
              })
              .then((response) => {
                if (response) {
                  this.loading = false;
                  // Set OTP to true if OTP is sent
                  // Send Notification
                  this.$notify({
                    title: "Success",
                    message: response.data.message,
                    type: "success",
                  });
                  this.showOTP = true;
                }
              })
              .catch((err) => {
                this.loading = false;
                if (err.response.status === 404) {
                  this.errors = err.response.data.message;
                }
                if (err.response?.data == undefined) {
                  this.errors = "Network Error.";
                }
              });
          } else {
            this.loading = false;
            return false;
          }
        });
      },
    },
    mounted() {
      // Analytics
      this.$mixpanel.track("Change Password Page");
      if (store.getters["auth/currentUser"].country === "CI") {
        this.$mixpanelFranco.track("Change Password Page");
      }
      return (this.phoneNumber = store.getters["auth/currentUser"]
        ? store.getters["auth/currentUser"].phone
        : "");
    },
  };
</script>
<style scoped>
  #account-settings {
    height: calc(100vh - 120px);
  }

  .profile_form {
    width: 385px;
    margin-left: 3em;
  }

  @media screen and (max-width: 500px) {
    .profile_form {
      width: 100%;
      margin-left: 0px;
    }
  }

  .warning {
    font-size: clamp(0.8rem, 0.5vw, 1rem);
    position: relative;
    top: 15px;
    color: red;
  }
</style>
<template>
  <div class="col-md-12">
    <div id="account-settings" class="mnotify-card">
      <div id="side-indicators-content" class="mnotify-card-header">
        <div class="profile-settings-section-header">
          <profilesettingsBtn />
          <div class="profile-settings-sub-header">
            <h3 class="mnotify-card-header-text pb-2 my-0">
              {{ $t("dashboard.account_settings.change_password.title") }}
            </h3>
            <p class="profile-description">
              {{ $t("dashboard.account_settings.change_password.sub_text") }}
            </p>
          </div>
        </div>
      </div>
      <div class="mnotify-card-body" v-if="showOTP === false">
        <h6 class="warning">
          {{ $t("dashboard.account_settings.change_password.note") }}
        </h6>
        <div class="row profile-settings-form" style="margin-top: 2em">
          <div class="profile_form">
            <div class="profile-inner">
              <el-form
                :model="formData"
                :rules="rules"
                ref="ruleForm"
                @submit.prevent.native="checkEnter">
                <div class="form_input_container">
                  <el-form-item
                    :label="
                      $t(
                        'dashboard.account_settings.change_password.form.current_password.label'
                      )
                    "
                    prop="old_password">
                    <el-input
                      type="password"
                      show-password
                      autocomplete="off"
                      :placeholder="
                        $t(
                          'dashboard.account_settings.change_password.form.current_password.placeholder'
                        )
                      "
                      v-model="formData.old_password"></el-input>
                  </el-form-item>
                </div>
                <div class="form_input_container">
                  <el-form-item
                    :label="
                      $t(
                        'dashboard.account_settings.change_password.form.new_password.label'
                      )
                    "
                    prop="password">
                    <el-input
                      type="password"
                      show-password
                      autocomplete="off"
                      :placeholder="
                        $t(
                          'dashboard.account_settings.change_password.form.new_password.placeholder'
                        )
                      "
                      v-model="formData.password"></el-input>
                  </el-form-item>
                </div>
                <div class="form_input_container">
                  <el-form-item
                    :label="
                      $t(
                        'dashboard.account_settings.change_password.form.confirm_password.label'
                      )
                    "
                    prop="password_confirmation">
                    <el-input
                      type="password"
                      show-password
                      autocomplete="off"
                      :placeholder="
                        $t(
                          'dashboard.account_settings.change_password.form.confirm_password.placeholder'
                        )
                      "
                      v-model="formData.password_confirmation"></el-input>
                  </el-form-item>
                </div>
                <Alert v-if="errors" :message="errors" :type="type" />
                <div class="terms_and_conditons_container mt-5">
                  <el-form-item>
                    <el-button
                      id="mNotify_form_submit_btn"
                      :loading="loading"
                      type="primary"
                      @click="requestOTP('ruleForm')"
                      >Change Password</el-button
                    >
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <!-- Enter OTP To Confirm -->
      <div class="mnotify-card-body" v-else>
        <h6 class="warning">
          You will be logged out after resetting your password
        </h6>
        <div class="row profile-settings-form" style="margin-top: 2em">
          <div class="profile_form">
            <div class="profile-inner">
              <el-form
                :model="formData"
                :rules="rules"
                ref="ruleForm"
                @submit.prevent.native="checkEnter">
                <div class="form_input_container">
                  <el-form-item label="Enter OTP to confirm password change">
                    <el-input
                      type="number"
                      show-password
                      autocomplete="off"
                      placeholder="Enter OTP"
                      v-model="formData.code"></el-input>
                  </el-form-item>
                </div>
                <Alert v-if="errors" :message="errors" :type="type" />
                <div class="terms_and_conditons_container mt-5">
                  <el-form-item>
                    <el-button
                      id="mNotify_form_submit_btn"
                      :loading="loading"
                      type="primary"
                      @click="submitForm('ruleForm')"
                      >{{
                        $t(
                          "dashboard.account_settings.change_password.form.button"
                        )
                      }}</el-button
                    >
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <!-- End -->
    </div>
  </div>
</template>
